import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import adminTheme from './assets/style/admin-theme';

import AAGunLocker from './screens/aa-gun-locker/AAGunLocker';
import Admin from './screens/admin/Admin';

import { MainContextProvider } from './context/MainContextProvider';

function App() {
  return (
    <MainContextProvider>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/admin">
            <ThemeProvider theme={adminTheme}>
              <Admin />
            </ThemeProvider>
          </Route>
          <Route path={['/', '/products', '/about', '*']}>
            <ThemeProvider theme={adminTheme}>
              <AAGunLocker />
            </ThemeProvider>
          </Route>
        </Switch>
      </Router>
    </MainContextProvider>
  );
}

export default App;
