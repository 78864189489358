import React, { createContext, useContext, useMemo } from "react";
import { AdminContextProvider } from "../context/AdminContextProvider";
import { useProductManager } from "../hooks/ProductHook";

export const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
  const productMananger = useProductManager();

  const productManagerContext = useMemo(
    () => ({
      ...productMananger,
    }),
    [productMananger]
  );

  return (
    <MainContext.Provider value={productManagerContext}>
      <AdminContextProvider>{children}</AdminContextProvider>
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};
