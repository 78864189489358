import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useMainContext } from '../../../context/MainContextProvider';
import { formUseStyles } from '../FormStyles';

const ConditionSelect = ({ onChange, value }) => {
  const { conditions } = useMainContext();
  const classes = formUseStyles();

  const getConditionOptions = () => {
    return conditions.items.map((v) => {
      return { value: v.id, label: v.type };
    });
  };

  return (
    <>
      <InputLabel id="conditionLabel">Condition</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="conditionLabel"
          value={value}
          onChange={onChange}
          options={getConditionOptions()}
        ></Select>
      </FormControl>
    </>
  );
};

ConditionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ConditionSelect;
