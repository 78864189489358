import React from 'react';
import ProductTable from '../../components/admin/product-table/ProductTable';

const ProductAdmin = () => {
  return (
    <ProductTable />
  );
};

export default ProductAdmin;
