import React from "react";
import Card from "../../components/card/Card";
// import shotgunsImage from "../../assets/images/guns/shotgun.jpg";
import airRiflesImage from "../../assets/images/guns/air-rifle.jpg";
import centreFiresImage from "../../assets/images/guns/centre-fire.jpg";
import accessoriesImage from "../../assets/images/guns/accessories.jpg";
import ammoImage from "../../assets/images/guns/ammo.jpg";
import clearanceImage from "../../assets/images/guns/clearance.png";

// import { uploadGunData } from "../../firebaseUploadData";

const Home = () => {
  return (
    <>
      <main
        class="site-main"
        style={{ backgroundColor: "#fff", paddingTop: 60 }}
      >
        <div class="container">
          <article class="content">
            <section class="post-full-content">
              <h1 class="content-title">Welcome to the Gun Locker</h1>
              <section class="content-body load-external-scripts">
                <p>
                  AA GUN LOCKER is a family run business with a wealth of
                  knowledge shared between the staff. With a passion for all
                  shooting styles and disciplines, we want to bring the sport
                  into the lives of everybody wanting to give it a try. Young or
                  old... it doesn`t matter. Everybody is learning.
                </p>
                <p>
                  Either pest control, target shooting or just back garden
                  plinking, we will do our best to suit your needs and keep to
                  your budget. Because we deal with many different brands, we
                  are able to make the choices easy and affordable... and we do
                  it well.
                </p>
                <p>
                  If we list it... it`s here. If it isn`t here, we can get it
                  within a couple of days. There is a constantly growing stock
                  of guns in different makes and models in a lot of different
                  calibers. It`s easy to find something suitable for a lot of
                  people. BUY, SELL OR PART EXCHANGE in confidence. We will
                  always do our best for you.
                </p>
                <p>We`ll see you soon, all the best, AA GUN LOCKER.</p>

                <h2>Our stock</h2>
                <div className="home-feed">
                  <Card
                    title="Rifles"
                    description="With a good stock of Rimfire rifles and a host of different makers, we try to cover all price brackets for you. Bolt action or Semi auto rifles are always moving in here. Centrefire rifles are in fewer amounts due to popularity, but we still have a few in and viewing is always free."
                    image={centreFiresImage}
                    path="Guns/Rifles"
                    contain
                    id="rifles"
                  />
                  <Card
                    title="Air Rifles"
                    description="We have a large amount of air rifles in store for you to view, all different calibres and all different makers. From the sub 12 to the FAC... New and traded in with warranty to suit. AIR ARMS, BSA, DAYSTATE, WEIRAUCH, KRAL and a host of other brands."
                    image={airRiflesImage}
                    path="Guns/Air Rifles"
                    contain
                    id="air"
                  />
                  <Card
                    title="Accessories"
                    description="We offer a wide range of accessories from mods, scopes, bipods... to gun chairs, tables, targets.The list just keeps growing."
                    image={accessoriesImage}
                    path="Accessories/"
                    id="accessories"
                  />
                  <Card
                    title="Ammo"
                    description="From pellets to centrefire, from bb`s to ball bearings. We carry a good stock in most of the time. Give us a call and see what we have... just remember, if we don`t have it in... we can get it."
                    image={ammoImage}
                    path="Ammo/"
                    id="ammo"
                  />
                  <Card
                    title="Clearance"
                    description="Welcome to the clearance section....Here you will find greatly reduced items that are very limited. When they are gone, they are gone. From ammo to rifles, scopes and other items on a first come first serve basis."
                    image={clearanceImage}
                    path="?clearance=true"
                    contain
                    double
                    id="clearance"
                  />
                </div>
              </section>
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default Home;
