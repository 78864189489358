import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../../pages/home-page/HomePage';
import NotFound from '../../pages/not-found/NotFound';
import ProductsPage from '../../pages/products-page/ProductsPage';
import AboutPage from '../../pages/about-page/AboutPage';
import ContactPage from '../../pages/contact-page/ContactPage';

const Main = () => {
  return (
    <>
      <Switch>
        <Route
          path={['/products', '/products/:pathCat', '/products/:pathCat/:pathSubCat']}
          component={ProductsPage}
        />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path={['/', '/home']} exact={true}>
          <Home />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default Main;
