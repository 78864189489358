import { db } from '../firebase';

export const getConditions = (callback) => {
  const conditions = [];
  db.collection('condition')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        conditions.push({ id: doc.id, ...doc.data() });
      });
      callback(conditions);
    })
    .catch(() => {
      callback({ hasError: true });
    });
};

