// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Assets
import '../../assets/style/App.css';
import imageLogo from '../../assets/images/gun-logo-aa-gray.png';
import imageBackground from '../../assets/images/aa-shop-50.png';
// Components

const SiteHeader = () => {

  return (
    <header className="site-head" style={{ backgroundImage: `url(${imageBackground})` }}>
      <div className="container">
        <div className="site-mast">
          <div className="site-mast-left">
            <a aria-current="page" className="" href="/">
              <img className="site-logo" src={imageLogo} alt="AA Gun Locker" />
            </a>
          </div>
          <div className="site-mast-right">
            <a
              href="https://www.facebook.com/AA-Gun-Locker-101012458409139"
              className="site-nav-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" />
            </a>
          </div>
        </div>
        <div className="site-banner">
          <h1 className="site-banner-title">AA Gun Locker</h1>
          <p className="site-banner-desc">Guns, Ammo, Accessories, Experience</p>
        </div>
        <nav className="site-nav">
          <div className="site-nav-left">
            <Link aria-current="page" className="site-nav-item" to="/">
              Home
            </Link>
            <Link className="site-nav-item" to="/about">
              About
            </Link>
            <Link className="site-nav-item" to="/contact">
              Contact
            </Link>
          </div>
          <div className="site-nav-right">
            <Link className="site-nav-button" to="/products">
              Products
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default SiteHeader;
