import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useAdminContext } from '../../context/AdminContextProvider';
import AdminLogin from '../../pages/admin-login/AdminLogin';
import { Sidebar, Topbar, Footer } from '../../components/admin';
import ProductAdmin from '../../pages/product-admin/ProductAdmin';
import CategoryAdmin from '../../pages/category-admin/CategoryAdmin';
import Dashboard from '../../pages/dashboard/Dashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}));

const Admin = ({ children }) => {
  const { isAuthenticated } = useAdminContext();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const renderAdmin = (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        <Switch>
          <Route path={['/admin']} exact={true}>
            <Dashboard />
          </Route>
          <Route path="/admin/dashboard">
            <Dashboard />
          </Route>
          <Route path="/admin/products">
            <ProductAdmin />
          </Route>
          <Route path="/admin/categories">
            <CategoryAdmin />
          </Route>
        </Switch>
        <Footer />
      </main>
    </div>
  );

  return isAuthenticated ? renderAdmin : <AdminLogin />;
};

Admin.propTypes = {
  children: PropTypes.node,
};

export default Admin;
