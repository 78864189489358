import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { formUseStyles } from "../FormStyles";

const ClearanceSelect = ({ onChange, checked }) => {
  const classes = formUseStyles();

  return (
    <>
      <InputLabel id="clearanceLabel">Show Only Clearance Items</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Switch
          checked={checked}
          onChange={onChange}
          name="clearance"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </FormControl>
    </>
  );
};

ClearanceSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ClearanceSelect;
