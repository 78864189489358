import React from "react";
import { getConditions } from "../apis/ConditionAPI";
import {
  getProducts,
  deleteProduct,
  createProduct,
  updateProduct,
} from "../apis/ProductAPI";
import {
  getCategories,
  getSubCategories,
  deleteSubCategory,
  createSubCategory,
  updateSubCategory,
} from "../apis/CategoryAPI";

const INITIAL_SUB_CATEGORIES = {
  items: [],
  loading: false,
};

const INITIAL_CATEGORIES = {
  items: [],
  loading: false,
  selected: null,
  parent: null,
};

const INITIAL_CONDITIONS = {
  items: [],
  loading: false,
};

const INITIAL_PRODUCTS = {
  items: [],
  loading: false,
};

export const useProductManager = () => {
  const [categories, setCategories] = React.useState(INITIAL_CATEGORIES);
  const [subCategories, setSubCategories] = React.useState(
    INITIAL_SUB_CATEGORIES
  );
  const [conditions, setConditions] = React.useState(INITIAL_CONDITIONS);
  const [isClearance, setIsClearance] = React.useState(false);
  const [products, setProducts] = React.useState(INITIAL_PRODUCTS);
  const [selectedProduct, setSelectedProduct] =
    React.useState(INITIAL_PRODUCTS);
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [initSelects, setInitSelects] = React.useState(true);

  React.useEffect(() => {
    loadAvailableProducts();
    loadAvailableCategories();
    loadAvailableSubCategories();
    loadAvailableConditions();
  }, []);

  React.useEffect(() => {
    if (searchText) {
      setFilteredProducts(
        products.items.filter((item) => {
          return (
            item.category.toLowerCase().includes(searchText.toLowerCase()) ||
            item.subCategory.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.price.includes(searchText) ||
            item.condition.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      );
    } else {
      setFilteredProducts(products.items);
    }
  }, [searchText, products]);

  const loadAvailableSubCategories = () => {
    setSubCategories({ loading: true, items: [] });
    getSubCategories((result) => {
      if (result.hasError) {
        setSubCategories(INITIAL_SUB_CATEGORIES);
      } else {
        setSubCategories({ items: result, loading: false });
      }
    });
  };

  const selectSubCategory = (subCategory) => {
    setSubCategories({ ...subCategories, selected: subCategory });
  };

  const removeSubCategory = (subCategory, callback) => {
    deleteSubCategory(subCategory.id, (result) => {
      if (result.hasError) {
        setSubCategories(INITIAL_SUB_CATEGORIES);
      } else {
        setSubCategories({ items: result, loading: false });
      }
      callback();
      loadAvailableSubCategories();
    });
  };

  const addSubCategory = (subCategory, callback) => {
    createSubCategory(subCategory, (result) => {
      callback();
      loadAvailableSubCategories();
    });
  };

  const editSubCategory = (subCategory, callback) => {
    updateSubCategory(subCategory, (result) => {
      callback();
      loadAvailableSubCategories();
    });
  };

  const selectCategory = (category) => {
    setSubCategories({ ...subCategories, selected: null });
    setConditions({ ...conditions, selected: null });
    setCategories({ ...categories, selected: category });
  };

  const selectCondition = (condition) => {
    setConditions({ ...conditions, selected: condition });
  };

  const loadAvailableCategories = () => {
    setCategories({ loading: true, items: [] });
    getCategories((result) => {
      if (result.hasError) {
        setCategories(INITIAL_CATEGORIES);
      } else {
        setCategories({ items: result, loading: false });
      }
    });
  };

  const loadAvailableConditions = () => {
    setConditions({ loading: true, items: [] });
    getConditions((result) => {
      if (result.hasError) {
        setConditions(INITIAL_CONDITIONS);
      } else {
        setConditions({ items: result, loading: false });
      }
    });
  };

  const loadAvailableProducts = () => {
    setProducts({ loading: true, items: [] });
    getProducts((result) => {
      if (result.hasError) {
        setProducts(INITIAL_PRODUCTS);
      } else {
        setProducts({ items: result, loading: false });
      }
    });
  };

  const resetSearchForm = () => {
    setCategories({ ...categories, selected: null });
    setSubCategories({ ...subCategories, selected: null });
    setConditions({ ...conditions, selected: null });
    setSearchText("");
    setIsClearance(false);
  };

  const removeProduct = (product, callback) => {
    deleteProduct(product.id, (result) => {
      if (result.hasError) {
        setProducts(INITIAL_PRODUCTS);
      } else {
        setProducts({ items: result, loading: false });
      }
      callback();
      loadAvailableProducts();
    });
  };

  const addProduct = (product, callback) => {
    createProduct(product, (result) => {
      callback();
      loadAvailableProducts();
    });
  };

  const editProduct = (product, callback) => {
    updateProduct(product, (result) => {
      callback();
      loadAvailableProducts();
    });
  };

  const getIdFromCategoryName = (name) => {
    return categories.items.filter((category) => category.name === name)[0]?.id;
  };

  const getIdFromSubCategoryName = (name) => {
    return subCategories.items.filter(
      (subCategory) => subCategory.name === name
    )[0]?.id;
  };

  const getNameFromCategoryId = (id) => {
    return categories.items.filter((category) => category.id === id)[0]?.name;
  };

  const getNameFromSubCategoryId = (id) => {
    return subCategories.items.filter((subCategory) => subCategory.id === id)[0]
      ?.name;
  };

  const getNameFromConditionId = (id) => {
    return conditions.items.filter((condition) => condition.id === id)[0]?.type;
  };

  const getTag = (cat, subCat) => {
    return subCategories.items.filter(
      (subCategory) => subCategory.id === subCat
    )[0]?.name;
  };

  return {
    categories,
    conditions,
    filteredProducts,
    products,
    searchText,
    selectCategory,
    selectSubCategory,
    selectCondition,
    setSearchText,
    resetSearchForm,
    removeProduct,
    addProduct,
    editProduct,
    getIdFromCategoryName,
    getIdFromSubCategoryName,
    getNameFromCategoryId,
    getNameFromSubCategoryId,
    getNameFromConditionId,
    getTag,
    selectedProduct,
    setSelectedProduct,
    loadAvailableSubCategories,
    addSubCategory,
    editSubCategory,
    removeSubCategory,
    subCategories,
    setSubCategories,
    setInitSelects,
    isClearance,
    setIsClearance,
  };
};
