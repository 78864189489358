import { db } from "../firebase";

const availableCats = [
  "undefined",
  // 'shotguns',
  "clearance",
  "rim_fires",
  "fac_air_rifles",
  "centre_fires",
  "air_rifles",
  "null",
];

export const getProducts = (callback) => {
  const products = [];
  db.collection("products")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        products.push({ id: doc.id, ...doc.data() });
      });
      callback(products);
    })
    .catch(() => {
      callback({ hasError: true });
    });
};

export const deleteProduct = (id, callback) => {
  db.collection("products")
    .doc(id)
    .delete()
    .then(function (result) {
      callback("Delete success: " + JSON.stringify(result));
    })
    .catch((error) => {
      console.log("Delete failed, see console,");
      console.warn(error);
      callback({ hasError: true });
    });
};

const getCompressedFilenames = (image) => {
  const getCategory = () => {
    for (var i = 0; i < availableCats.length; i++) {
      if (image?.includes(`%2F${availableCats[i]}%2F`)) {
        return availableCats[i];
      }
    }
  };

  const cat = getCategory();
  const compressed = image.replace(`%2F${cat}%2F`, `%2F${cat}%2Fcompressed%2F`);
  const image_small = compressed
    .replace(".jpg", "_400x300.jpg")
    .replace(".JPG", "_400x300.jpg")
    .replace(".png", "_400x300.png")
    .replace(".PNG", "_400x300.PNG");
  const image_large = compressed
    .replace(".jpg", "_800x600.jpg")
    .replace(".JPG", "_400x300.jpg")
    .replace(".png", "_400x300.png")
    .replace(".PNG", "_400x300.PNG");

  return { image_small, image_large };
};

export const createProduct = (newProduct, callback) => {
  const {
    name,
    description,
    price,
    category,
    subCategory,
    image,
    videoUrl,
    condition,
    inStock,
    isClearance,
    stockCount,
  } = newProduct;
  const { image_small, image_large } = getCompressedFilenames(image);

  db.collection("products")
    .add({
      name,
      description,
      price,
      category,
      subCategory: subCategory || "",
      image,
      image_small,
      image_large,
      videoUrl,
      condition,
      inStock,
      isClearance,
      stockCount: stockCount || 0,
    })
    .then(function (docRef) {
      callback(docRef.id);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      callback({ hasError: true });
    });
};

export const exportProductData = async () => {
  var productRef = db.collection("products");
  var exportData = "";
  productRef.map((product) => {
    const {
      id,
      name,
      description,
      price,
      category,
      subCategory,
      image,
      videoUrl,
      condition,
      inStock,
      isClearance,
      stockCount,
    } = product;

    exportData =
      exportData +
      `${id},${name},${description},${price},${category},${subCategory},${image},${videoUrl},${condition},${inStock},${isClearance},${stockCount}\n`;
  });

  return exportData;
};

export const updateProduct = async (product, callback) => {
  const {
    id,
    name,
    description,
    price,
    category,
    subCategory,
    image,
    videoUrl,
    condition,
    inStock,
    isClearance,
    stockCount,
  } = product;

  const { image_small, image_large } = getCompressedFilenames(image);
  var productRef = db.collection("products").doc(id);

  try {
    await productRef.update({
      name,
      description,
      price,
      category,
      subCategory,
      image,
      image_small,
      image_large,
      videoUrl: videoUrl || "",
      condition,
      inStock,
      isClearance: isClearance || false,
      stockCount: stockCount || 0,
    });
    callback(id);
  } catch (error) {
    console.log({ error });
    callback({ hasError: true });
  }
};
