import { db } from '../firebase';

export const createMessage = (enquiry, callback) => {
  const { fullName, email, telephone, message } = enquiry;
  db.collection('messages')
    .add({
      to: ['grizzlydigitalapps@gmail.com'],
      message: {
        subject: 'New enquiry from AA Gun Locker website',
        html: `<p style="font-size: 16px;">Name: ${fullName}</p><br />
            <p style="font-size: 16px;">Email: ${email}</p><br />
            <p style="font-size: 16px;">Tel: ${telephone}</p><br />
            <p style="font-size: 16px;">Message: ${message}</p><br />`,
      },
    })
    .then(function (docRef) {
      callback(docRef.id);
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
      callback({ hasError: true });
    });
};