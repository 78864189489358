import React from 'react';
import '../../assets/style/App.css';
import Header from '../../components/header/Header';
import Main from '../../components/main/Main';
import Footer from '../../components/footer/Footer';

const HomeScreen = () => {
  return (
    <div className="viewport">
      <div className="viewport-top">
        <Header />
        <Main />
      </div>
      <div className="viewport-bottom">
        <Footer />
      </div>
    </div>
  );
};

export default HomeScreen;
