import React from 'react';
import { Link } from 'react-router-dom';
import imageLogoBanner from '../../assets/images/gun-logo-aa-gray.png';

// Components

const Footer = () => {

  return (
    <footer className="site-foot">
      <div className="site-foot-nav container">
        <div className="site-foot-nav-left">
          <a aria-current="page" className="" href="/">
            <img
              className="site-logo"
              src={imageLogoBanner}
              alt="AA Gun Locker"
              style={{ width: 80, height: 'auto', marginLeft: 30 }}
            />
            <br />
            <br />
            &nbsp;&nbsp;AA Gunlocker
          </a>
          © 2020
        </div>
        <div className="site-foot-nav-right">
          <Link aria-current="page" className="site-foot-nav-item" to="/">
            Home
          </Link>
          <Link className="site-foot-nav-item" to="/about">
            About
          </Link>
          <Link className="site-foot-nav-item" to="/contact">
            Contact
          </Link>
          <Link className="site-foot-nav-button" to="/products">
            Products
          </Link>
          <Link className="site-foot-nav-button" to="/admin/products">
            Admin
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
