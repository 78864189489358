import { db } from '../firebase';

export const getCategories = (callback) => {
  const categories = [];
  db.collection('category')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
      callback(categories);
    })
    .catch(() => {
      callback({ hasError: true });
    });
};

export const getSubCategories = (callback) => {
  const subCategories = [];
  db.collection('subCategory')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        subCategories.push({ id: doc.id, ...doc.data() });
      });
      callback(subCategories);
    })
    .catch(() => {
      callback({ hasError: true });
    });
};

// deleteSubCategory, createSubCategory, updateSubCategory

export const deleteSubCategory = (id, callback) => {
  db.collection('subCategory')
    .doc(id)
    .delete()
    .then(function (result) {
      callback('Delete success: ' + JSON.stringify(result));
    })
    .catch((error) => {
      console.log('Delete failed, see console,');
      console.warn(error);
      callback({ hasError: true });
    });
};

export const createSubCategory = (newSubCategory, callback) => {
  const { name, parent } = newSubCategory;

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  };

  const id = `sub_category_${camelize(name)}`;
  const imageStore = `${parent}/${name.trim().toLowerCase().replace(' ', '_')}`;

  db.collection('subCategory')
    .doc(id)
    .set({
      name,
      parent,
      imageStore,
    })
    .then(function (docRef) {
      callback(docRef.id);
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
      callback({ hasError: true });
    });
};

export const updateSubCategory = async (subCategory, callback) => {
  const { id, name, parent } = subCategory;
  var subCategoryRef = db.collection('subCategory').doc(id);

  try {
    await subCategoryRef.update({
      name,
      parent,
    });
    callback(id);
  } catch (error) {
    callback({ hasError: true });
  }
};
