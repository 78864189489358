import React from "react";
import { useLocation } from "react-router-dom";
import ProductDetail from "../../components/product-detail/ProductDetail";
import ProductList from "../../components/product-list/ProductList";
import ProductSearch from "../../components/product-search/ProductSearch";
import { useMainContext } from "../../context/MainContextProvider";

const ProductsPage = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedSubCategory, setSelectedSubCategory] = React.useState("");
  const [detailOpen, setDetailOpen] = React.useState(false);
  const { setSelectedProduct, setIsClearance } = useMainContext();

  const setSearch = () => {
    const params = location.pathname.split("/").filter(String);
    const search = location.search.replace("?", "").split("=");

    if (params?.length > 1) {
      if (params?.length > 2) {
        setSelectedCategory(params[1]);
        setSelectedSubCategory(params[2]);
      } else {
        setSelectedCategory(params[1]);
      }
    }

    if (search[0] === "clearance" && search[1] === "true") {
      setIsClearance(true);
    }
  };

  React.useEffect(() => {
    setSearch();
  }, []);

  const closeDetail = () => {
    setDetailOpen(false);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setDetailOpen(true);
  };

  return (
    <main className="site-main" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <ProductSearch
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
        />
        <ProductList handleProductClick={handleProductClick} />
        <ProductDetail open={detailOpen} onCloseClick={closeDetail} />
      </div>
    </main>
  );
};

export default ProductsPage;
