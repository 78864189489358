import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import imageBackground from '../../assets/images/aa-shop.png';
import imageLogoBanner from '../../assets/images/gun-logo-aa-small.png';
import { useAdminContext } from '../../context/AdminContextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundImage: `url(${imageBackground})`,
    backgroundPosition: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 'auto auto',
      width: theme.spacing(32),
      height: theme.spacing(48),
      display: 'grid',
      justifyItems: 'center',
      paddingTop: 20,
      gridAutoRows: 'min-content',
    },
  },
  firstInput: {
    marginTop: 40,
  },
  input: {
    marginTop: 40,
  },
  button: {
    marginTop: 80,
    backgroundColor: '#282c34',
  },
}));

const DEFAULT_CREDS = {
  email: { value: '', pristine: true },
  password: { value: '', pristine: true },
};

const AdminLogin = () => {
  const classes = useStyles();
  const [creds, setCreds] = React.useState(DEFAULT_CREDS);
  const { userSignIn, loginError } = useAdminContext();

  const handleLogin = () => {
    return userSignIn(creds.email.value, creds.password.value);
  };

  const updateCreds = (field) => {
    setCreds({ ...creds, ...field });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <a aria-current="page" className="" href="/">
          <img className="site-logo" src={imageLogoBanner} alt="AA Gun Locker" />
        </a>
        <TextField
          id="standard-basic"
          label="Email"
          value={creds.email.value}
          className={classes.firstInput}
          type="email"
          onChange={(v) => {
            return updateCreds(
              v.target.value
                ? { email: { value: v.target.value, pristine: false } }
                : { email: { value: '', pristine: true } }
            );
          }}
          autoComplete="username"
        />
        <TextField
          id="standard-basic"
          label="Password"
          value={creds.password.value}
          type="password"
          onChange={(v) =>
            updateCreds(
              v.target.value
                ? { password: { value: v.target.value, pristine: false } }
                : { password: { value: '', pristine: true } }
            )
          }
          autoComplete="password"
        />
        <p style={{ color: 'red', marginTop: 10, fontSize: 14 }}>{loginError}</p>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleLogin}
          disabled={creds.email.pristine && creds.password.pristine}
        >
          Login
        </Button>
      </Paper>
    </div>
  );
};

export default AdminLogin;
