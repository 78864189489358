import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Link, Button } from "@material-ui/core";
import { exportProductData } from "../../../apis/ProductAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{" "}
        <Link component="a" href="/">
          AA Gun Locker
        </Link>
        . 2020
      </Typography>
      <Typography variant="caption">
        Created for use with AA Gun Locker
      </Typography>
      <Button onClick={exportProductData}>Download Data</Button>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
