import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useContact } from "../../hooks/ContactHook";

const DEFAULT_ENQUIRY = {
  fullName: "",
  email: "",
  telephone: "",
  message: "",
};

const ContactPage = () => {
  const [enquiry, setEnquiry] = React.useState(DEFAULT_ENQUIRY);
  const { addMessage, messageSent } = useContact();

  const handleSend = () => {
    addMessage(enquiry, (res) => {
      setEnquiry(DEFAULT_ENQUIRY);
    });
  };

  return (
    <main
      class="site-main"
      style={{ backgroundColor: "#fff", paddingTop: 120 }}
    >
      <div class="container">
        <article class="content">
          <section class="post-full-content">
            <h1>Contact Us</h1>
            <section>
              <h3>Phone</h3>
              <p>01785 241702</p>
              <h3>Address</h3>
              <p>
                Unit 4 Marston Road Trading Park,
                <br />
                Marston Rd,
                <br />
                Stafford
                <br />
                ST16 3GB
              </p>
              <h3>Email</h3>
              <p>
                <a href="mailto:fishingrivernet@yahoo.co.uk" about="_blank">
                  Click to email
                </a>
              </p>
            </section>
            <p>
              You can also contact us by filling in the below form and clicking
              send, we will get back to you as soon as we can.
            </p>
            <FormGroup>
              <TextField
                margin="normal"
                id="name"
                label="Full Name"
                type="text"
                fullWidth
                defaultValue={enquiry?.name}
                onBlur={(event) => {
                  setEnquiry({ ...enquiry, name: event.target.value });
                }}
                variant="outlined"
              />
              <TextField
                margin="normal"
                id="email"
                label="Email"
                type="text"
                fullWidth
                defaultValue={enquiry?.email}
                onBlur={(event) => {
                  setEnquiry({ ...enquiry, email: event.target.value });
                }}
                variant="outlined"
              />
              <TextField
                margin="normal"
                id="telephone"
                label="Telephone (optional)"
                type="text"
                fullWidth
                defaultValue={enquiry?.telephone}
                onBlur={(event) => {
                  setEnquiry({ ...enquiry, telephone: event.target.value });
                }}
                variant="outlined"
              />
              <TextField
                margin="normal"
                id="message"
                label="Your enquiry"
                type="text"
                fullWidth
                multiline
                rows={6}
                defaultValue={enquiry?.message}
                onBlur={(event) => {
                  setEnquiry({ ...enquiry, message: event.target.value });
                }}
                variant="outlined"
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSend}
                style={{ maxWidth: 120, marginTop: 10 }}
              >
                Send
              </Button>
            </FormGroup>
            <br />
            {messageSent && (
              <p>Message sent, we will get back to you as soon as possible.</p>
            )}
          </section>
        </article>
      </div>
    </main>
  );
};

export default ContactPage;
