import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import { useMainContext } from "../../context/MainContextProvider";

const ProductDetail = ({ open, onCloseClick }) => {
  const { selectedProduct } = useMainContext();
  const { image_large } = selectedProduct;
  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <img
          src={image_large}
          style={{ maxWidth: "100%", maxHeight: 800 }}
          alt="Large version"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDetail;
