import { makeStyles } from '@material-ui/core/styles';

export const formUseStyles = makeStyles({
  formControl: {
    minWidth: 180,
    marginTop: 10,
    marginBottom: 20,
    fontSize: 11,
  },
});
