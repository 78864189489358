import React from 'react';
import { auth } from './../firebase';

export const useAdminAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loginError, setLoginError] = React.useState('');

  React.useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  });

  const userSignIn = (email, password) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setLoginError('Email or password incorrect.');
        setIsAuthenticated(false);
      });
  };

  const userSignOut = () => {
    auth.signOut();
  };

  return {
    userSignIn,
    userSignOut,
    loginError,
    isAuthenticated,
  };
};
