// src/firebase.js

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDW9bgZ7oBVIGaQ0w74zf8Uxn_ZNbzcavg",
  authDomain: "aa-gun-locker.firebaseapp.com",
  databaseURL: "https://aa-gun-locker.firebaseio.com",
  projectId: "aa-gun-locker",
  storageBucket: "aa-gun-locker.appspot.com",
  messagingSenderId: "453382142487",
  appId: "1:453382142487:web:10e6d846e1bf52b1733a52",
  measurementId: "G-97R7Q1K5JV",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// console.log('firebase', firebase);

export const db = firebase.firestore();
export const st = firebase.storage();
export const fn = firebase.functions();
export const auth = firebase.auth();
