import React from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import { useMainContext } from "../../../context/MainContextProvider";
import CategorySelect from "../../form-controls/category-select/CategorySelect";

const categoryLookup = {
  category_guns: "Guns",
  category_ammo: "Ammo",
  category_accessories: "Accessories",
};

const DEFAULT_SUB_CATEGORY = {
  id: null,
  imageStore: "",
  name: "",
  parent: "",
};

const SUB_CATEGORY_COLUMNS = [
  { title: "Name", field: "name", filtering: false },
  { title: "Category", field: "parent", lookup: categoryLookup },
];

const CategoryTable = () => {
  const {
    subCategories,
    selectSubCategory,
    removeCategory,
    addSubCategory,
    editSubCategory,
  } = useMainContext();
  const [editCategoryOpen, setEditCategoryOpen] = React.useState(false);
  const [deleteCategoryOpen, setDeleteCategoryOpen] = React.useState(false);
  const [selectedSubCategory, setSelectedSubCategory] =
    React.useState(DEFAULT_SUB_CATEGORY);

  const populateForm = (rowData) => {
    selectSubCategory({
      label: categoryLookup[rowData.parent],
      value: rowData.parent,
    });
  };

  const handleSubCategoryChange = (newValue) => {
    setSelectedSubCategory({ ...selectedSubCategory, ...newValue });
  };

  const handleDeleteCategory = () => {
    setDeleteCategoryOpen(false);
    return removeCategory(selectedSubCategory, () =>
      setSelectedSubCategory(DEFAULT_SUB_CATEGORY)
    );
  };

  const handleEditCategory = () => {
    setEditCategoryOpen(false);
    return selectedSubCategory?.id
      ? editSubCategory(selectedSubCategory, () =>
          setSelectedSubCategory(DEFAULT_SUB_CATEGORY)
        )
      : addSubCategory(selectedSubCategory, () =>
          setSelectedSubCategory(DEFAULT_SUB_CATEGORY)
        );
  };

  return (
    <div>
      <MaterialTable
        style={{ fontSize: 14 }}
        title={
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setEditCategoryOpen(true)}
          >
            Add Category
          </Button>
        }
        columns={SUB_CATEGORY_COLUMNS}
        data={subCategories.items}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          filtering: true,
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Category",
            onClick: (event, rowData) => {
              setSelectedSubCategory(rowData);
              populateForm(rowData);

              return setEditCategoryOpen(true);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Category",
            onClick: (event, rowData) => {
              setSelectedSubCategory(rowData);
              return setDeleteCategoryOpen(true);
            },
          },
        ]}
      />
      <Dialog
        open={editCategoryOpen}
        onClose={() => {
          setSelectedSubCategory(null);
          return setEditCategoryOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`${
          selectedSubCategory?.id ? "Edit" : "Create"
        } Category`}</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <DialogContentText>
            {`Complete all fields and save to ${
              selectedSubCategory?.id ? "edit" : "create"
            } subcategory.`}
          </DialogContentText>
          <FormGroup>
            <CategorySelect
              value={{
                label: selectedSubCategory
                  ? categoryLookup[selectedSubCategory.parent]
                  : categoryLookup[Object.keys(categoryLookup)[0]],
                value: selectedSubCategory
                  ? selectedSubCategory?.parent
                  : Object.keys(categoryLookup)[0],
              }}
              onChange={(selected) =>
                handleSubCategoryChange({ parent: selected.value })
              }
              style={{ marginRight: 0, zIndex: 2110 }}
              error={!selectedSubCategory?.parent}
            />
            <TextField
              autoFocus
              margin="normal"
              id="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedSubCategory?.name}
              onBlur={(event) => {
                handleSubCategoryChange({ name: event.target.value });
              }}
              variant="outlined"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedSubCategory(null);
              return setEditCategoryOpen(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditCategory}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteCategoryOpen}
        onClose={() => setDeleteCategoryOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete subcategory?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete this subcategory?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteCategoryOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteCategory}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryTable;
