import React from "react";
import ReactHtmlParser from "react-html-parser";

const Card = ({ title, image, description, path, contain = false, id }) => {
  const bgImage = `url(${image})`;

  return (
    <a className={`post-card ${id}`} href={`/products/${path || ""}`}>
      <header className="post-card-header">
        <div
          className="post-card-image"
          style={{
            backgroundSize: contain ? `contain` : "",
            backgroundImage: bgImage,
            backgroundColor: "#fff",
          }}
        ></div>
        <div className="post-card-tags"></div>
        <h3 className="post-card-title">{title}</h3>
      </header>
      <section className="post-card-excerpt">
        <>{ReactHtmlParser(description)}</>
      </section>
      <footer className="post-card-footer">
        <div className="post-card-footer-left">
          <span style={{ fontWeight: "bold", fontSize: 11 }}></span>
        </div>
      </footer>
    </a>
  );
};

export default Card;
