import React from "react";
import MaterialTable from "material-table";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { st } from "../../../firebase";
import Product from "../../product/Product";
import { useMainContext } from "../../../context/MainContextProvider";
import CategorySelect from "../../form-controls/category-select/CategorySelect";
import SubcategorySelect from "../../form-controls/subcategory-select/SubcategorySelect";
import ConditionSelect from "../../form-controls/condition-select/ConditionSelect";

const categoryLookup = {
  category_guns: "Guns",
  category_ammo: "Ammo",
  category_accessories: "Accessories",
  category_clearance: "Clearance",
};

const subCategoryLookup = {
  sub_category_rimFires: "Rimfires",
  sub_category_centreFires: "Centrefires",
  sub_category_airRifles: "Air Rifles",
  sub_category_facAirRifles: "FAC Air Rifles",
  sub_category_shotguns: "Shotguns",
};

const conditionLookup = {
  condition_new: "New",
  condition_good: "Good",
  condition_average: "Average",
  condition_poor: "Poor",
};

const DEFAULT_UPLOADER = {
  filenames: [],
  downloadURLs: [],
  isUploading: false,
  uploadProgress: 0,
  uploadUrl: null,
};

const DEFAULT_PRODUCT = {
  id: null,
  name: "",
  description: "",
  price: 0,
  category: "",
  subCategory: "",
  image: "",
  videoUrl: "",
  condition: "",
  inStock: true,
  isClearance: false,
  stockCount: 0,
};

const ProductTable = () => {
  const {
    products,
    categories,
    subCategories,
    removeProduct,
    addProduct,
    editProduct,
    selectCategory,
    selectSubCategory,
  } = useMainContext();
  const [uploader, setUploader] = React.useState(DEFAULT_UPLOADER);
  const [editProductOpen, setEditProductOpen] = React.useState(false);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(DEFAULT_PRODUCT);
  // const [newProduct, setNewProduct] = React.useState(DEFAULT_PRODUCT);

  const getSubCategoryLookup = () => {
    let lookup = {};
    subCategories.items.map((subcat) => {
      return (lookup[subcat.id] = subcat.name);
    });

    return lookup;
  };

  const handleUploadStart = () =>
    setUploader({ ...uploader, isUploading: true, uploadProgress: 0 });

  const handleProgress = (progress) =>
    setUploader({ ...uploader, uploadProgress: progress });

  const handleUploadError = (error) => {
    setUploader({
      ...uploader,
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  const getImageFolder = (rowData) => {
    const { category, subCategory } = rowData;
    if (subCategory) {
      return subCategories.items?.filter(
        (subcat) => subcat.id === subCategory
      )[0]?.imageStore;
    }

    return categories.items?.filter((cat) => cat.id === category)[0]
      ?.imageStore;
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await st
      .ref(`products/${uploader.uploadUrl}`)
      .child(filename)
      .getDownloadURL();
    handleProductChange({ image: downloadURL });
    setUploader((uploader) => ({
      filenames: [...uploader.filenames, filename],
      downloadURLs: [...uploader.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: true,
    }));
  };

  const handleClearImage = () => {
    setUploader(() => ({
      filenames: [],
      downloadURLs: [],
      uploadProgress: 0,
      isUploading: false,
    }));
    handleProductChange({ image: "" });
  };

  const PRODUCT_COLUMNS = [
    { title: "Name", field: "name", filtering: false },
    {
      title: "Stock Status",
      field: "inStock",
      lookup: { true: "In Stock", false: "Out of Stock" },
    },
    {
      title: "Stock Count",
      field: "stockCount",
      type: "number",
      filtering: true,
    },
    {
      title: "Is Clearance",
      field: "isClearance",
      lookup: { true: "Is Clearance Item", false: "Not Clearance Item" },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) =>
        rowData.description.replace(/^(.{60}[^\s]*).*/, "$1" + "..."),
      filtering: false,
      editComponent: (props) => (
        <textarea
          rows={4}
          style={{ width: 400, height: 150 }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
    { title: "Condition", field: "condition", lookup: conditionLookup },
    {
      title: "Price",
      field: "price",
      type: "currency",
      currencySetting: {
        locale: "gb",
        currencyCode: "gbp",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
      filtering: false,
    },
    { title: "Category", field: "category", lookup: categoryLookup },
    {
      title: "Subcategory",
      field: "subCategory",
      lookup: getSubCategoryLookup(),
    },
    {
      title: "Image",
      field: "image",
      render: (rowData) => (
        <img
          src={rowData.image_small}
          alt={rowData.title}
          style={{ width: 80, borderRadius: "20%" }}
        />
      ),
      filtering: false,
    },
  ];

  const handleDeleteProduct = () => {
    setDeleteProductOpen(false);
    return removeProduct(selectedProduct, () =>
      setSelectedProduct(DEFAULT_PRODUCT)
    );
  };

  const handleEditProduct = () => {
    setEditProductOpen(false);
    return selectedProduct?.id
      ? editProduct(selectedProduct, () => setSelectedProduct(DEFAULT_PRODUCT))
      : addProduct(selectedProduct, () => setSelectedProduct(DEFAULT_PRODUCT));
  };

  const handleProductChange = (newValue) => {
    setSelectedProduct({ ...selectedProduct, ...newValue });
  };

  const populateForm = (rowData) => {
    selectCategory({
      label: categoryLookup[rowData.category],
      value: rowData.category,
    });
    selectSubCategory({
      label: getSubCategoryLookup()[rowData.subCategory],
      value: rowData.subCategory,
    });
    //selectCondition({ label: })
  };

  return (
    <div>
      <MaterialTable
        style={{ fontSize: 14 }}
        title={
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setEditProductOpen(true)}
          >
            Add Product
          </Button>
        }
        columns={PRODUCT_COLUMNS}
        data={products.items}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          filtering: true,
        }}
        detailPanel={(rowData) => {
          return (
            <div
              style={{
                padding: 20,
                maxWidth: 400,
                margin: 20,
                border: "1px solid lightgray",
              }}
            >
              <Product product={rowData} />
            </div>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedProduct(rowData);
          return togglePanel();
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Product",
            onClick: (event, rowData) => {
              setSelectedProduct(rowData);
              populateForm(rowData);

              setUploader({ ...uploader, uploadUrl: getImageFolder(rowData) });
              return setEditProductOpen(true);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Product",
            onClick: (event, rowData) => {
              setSelectedProduct(rowData);
              return setDeleteProductOpen(true);
            },
          },
        ]}
      />
      <Dialog
        open={editProductOpen}
        onClose={() => {
          setSelectedProduct(null);
          return setEditProductOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`${
          selectedProduct?.id ? "Edit" : "Create"
        } Product`}</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <DialogContentText>
            {`Complete all fields and save to ${
              selectedProduct?.id ? "edit" : "create"
            } product.`}
          </DialogContentText>
          <FormGroup>
            <CategorySelect
              value={{
                label: categoryLookup[selectedProduct?.category],
                value: selectedProduct?.category,
              }}
              onChange={(selected) =>
                handleProductChange({ category: selected.value })
              }
              style={{ marginRight: 0, zIndex: 2110 }}
              error={!selectedProduct?.category}
            />
            <SubcategorySelect
              value={{
                label: getSubCategoryLookup()[selectedProduct?.subCategory],
                value: selectedProduct?.subCategory,
              }}
              onChange={(selected) =>
                handleProductChange({ subCategory: selected.value })
              }
              style={{ marginRight: 0 }}
            />
            {categories.selected?.value !== "category_ammo" && (
              <ConditionSelect
                value={{
                  label: conditionLookup[selectedProduct?.condition],
                  value: selectedProduct?.condition,
                }}
                onChange={(selected) =>
                  handleProductChange({ condition: selected.value })
                }
                style={{ marginRight: 0 }}
              />
            )}

            <InputLabel id="conditionLabel" style={{ marginBottom: 20 }}>
              Image
            </InputLabel>
            {!uploader.downloadURLs.length ? (
              <>
                <span style={{ fontSize: 11 }}>
                  <a
                    href={selectedProduct?.image || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedProduct?.image || ""}
                  </a>
                  {/* <img
                    src={selectedProduct?.image}
                    alt={selectedProduct?.title || ""}
                  /> */}
                </span>
                <br />

                <CustomUploadButton
                  accept="image/*"
                  storageRef={st.ref(`products/${uploader.uploadUrl}`)}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={(filename) => {
                    return handleUploadSuccess(filename);
                  }}
                  onProgress={handleProgress}
                  style={{
                    backgroundColor: "white",
                    color: "#2979ff",
                    border: "1px solid #2979ff",
                    padding: 12,
                    borderRadius: 4,
                    display: "block",
                    textAlign: "center",
                    fontSize: 12,
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Select Image
                </CustomUploadButton>
              </>
            ) : (
              <>
                <span style={{ fontSize: 11 }}>
                  <a
                    href={uploader.downloadURLs[0] || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {uploader.downloadURLs[0] || ""}
                  </a>
                  {/* <img
                    src={uploader.downloadURLs[0]}
                    alt={selectedProduct?.title || ""}
                  /> */}
                </span>
                <Button
                  variant="outlined"
                  onClick={handleClearImage}
                  color="primary"
                >
                  Clear Image
                </Button>
              </>
            )}

            <TextField
              autoFocus
              margin="normal"
              id="video"
              label="Video Url (from Vimeo)"
              type="text"
              fullWidth
              defaultValue={selectedProduct?.videoUrl}
              onBlur={(event) => {
                handleProductChange({ videoUrl: event.target.value });
              }}
              variant="outlined"
            />

            <TextField
              autoFocus
              margin="normal"
              id="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedProduct?.name}
              onBlur={(event) => {
                handleProductChange({ name: event.target.value });
              }}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="normal"
              id="description"
              label="Description"
              multiline
              rows={6}
              defaultValue={selectedProduct?.description}
              fullWidth
              onBlur={(selected) =>
                handleProductChange({ description: selected.target.value })
              }
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="normal"
              id="price"
              label="Price"
              type="number"
              fullWidth
              defaultValue={selectedProduct?.price}
              onBlur={(selected) =>
                handleProductChange({ price: selected.target.value })
              }
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="normal"
              id="stockCount"
              label="Number in stock"
              type="number"
              fullWidth
              defaultValue={selectedProduct?.stockCount || 0}
              onBlur={(selected) =>
                handleProductChange({ stockCount: selected.target.value })
              }
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Switch
                  size="medium"
                  checked={selectedProduct?.inStock}
                  onChange={() =>
                    handleProductChange({ inStock: !selectedProduct?.inStock })
                  }
                />
              }
              label={selectedProduct?.inStock ? "In Stock" : "Out of Stock"}
            />
            <FormControlLabel
              control={
                <Switch
                  size="medium"
                  checked={selectedProduct?.isClearance}
                  onChange={() =>
                    handleProductChange({
                      isClearance: !selectedProduct?.isClearance,
                    })
                  }
                />
              }
              label={
                selectedProduct?.isClearance
                  ? "Is Clearance Item"
                  : "Not Clearance Item"
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedProduct(null);
              return setEditProductOpen(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditProduct}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteProductOpen}
        onClose={() => setDeleteProductOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete product?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteProductOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteProduct}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductTable;
