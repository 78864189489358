import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useMainContext } from '../../../context/MainContextProvider';
import { formUseStyles } from '../FormStyles';

const CategorySelect = ({ onChange, value }) => {
  const { categories } = useMainContext();
  const classes = formUseStyles();

  const getCategoryOptions = () => {
    return categories.items.map((v) => {
      return { value: v.id, label: v.name };
    });
  };

  return (
    <>
      <InputLabel id="catLabel">Category</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="catLabel"
          native
          value={value}
          onChange={onChange}
          label="Category"
          options={getCategoryOptions()}
        ></Select>
      </FormControl>
    </>
  );
};

CategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CategorySelect;
