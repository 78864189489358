import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";

import { useMainContext } from "../../context/MainContextProvider";
import CategorySelect from "../form-controls/category-select/CategorySelect";
import SubcategorySelect from "../form-controls/subcategory-select/SubcategorySelect";
import ConditionSelect from "../form-controls/condition-select/ConditionSelect";
import ClearanceSelect from "../form-controls/clearance-select/ClearanceSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "none",
    border: "1px solid #f1f1f1",
    borderRadius: 2,
  },
  heading: {
    textDecoration: "underline",
  },
}));

const ProductSearch = ({ selectedCategory, selectedSubCategory }) => {
  const {
    categories,
    subCategories,
    conditions,
    isClearance,
    setIsClearance,
    searchText,
    selectCategory,
    selectSubCategory,
    selectCondition,
    setSearchText,
    resetSearchForm,
    getIdFromCategoryName,
    getIdFromSubCategoryName,
    loadAvailableSubCategories,
  } = useMainContext();
  const classes = useStyles();
  const [changeCategory, setChangeCategory] = React.useState(true);
  const [changeSubCategory, setChangeSubCategory] = React.useState(true);

  React.useEffect(() => {
    if (selectedCategory && !categories.loading && changeCategory) {
      selectCategory({
        label: selectedCategory,
        value: getIdFromCategoryName(selectedCategory),
      });
      setChangeCategory(false);
      loadAvailableSubCategories();
    }
  }, [categories]);

  React.useEffect(() => {
    if (selectedSubCategory && !subCategories.loading && changeSubCategory) {
      selectSubCategory({
        label: selectedSubCategory,
        value: getIdFromSubCategoryName(selectedSubCategory),
      });
      setChangeSubCategory(false);
    }
  }, [subCategories.loading]);

  return (
    <Accordion className={classes.container} expanded>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<RefreshIcon  />}
      >
        <Typography className={classes.heading}>Filter Products</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={11}>
                <TextField
                  id="standard-basic"
                  label="Text Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} justify="flex-start">
              <Grid item xs={12} md={3}>
                <CategorySelect
                  onChange={selectCategory}
                  value={categories.selected}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SubcategorySelect
                  onChange={selectSubCategory}
                  value={subCategories.selected}
                  parent={categories.selected?.value || ""}
                />
              </Grid>
              {categories.selected?.value !== "category_ammo" && (
                <Grid item xs={12} md={3}>
                  <ConditionSelect
                    onChange={selectCondition}
                    value={conditions.selected}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <ClearanceSelect
                  onChange={() => setIsClearance(!isClearance)}
                  checked={isClearance}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductSearch;
