import React from "react";
import Product from "../product/Product";
import { useMainContext } from "../../context/MainContextProvider";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const ProductList = ({ handleProductClick }) => {
  const {
    filteredProducts,
    categories,
    subCategories,
    conditions,
    isClearance: clearanceSelected,
  } = useMainContext();

  const renderProducts = filteredProducts?.map((item, i) => {
    const product = (
      <LazyLoadComponent key={i}>
        <Product
          product={item}
          key={i}
          handleProductClick={handleProductClick}
        />
      </LazyLoadComponent>
    );

    const { condition, category, subCategory, isClearance } = item;

    if (!clearanceSelected || clearanceSelected === isClearance) {
      if (!categories.selected) {
        if (!conditions.selected) {
          return product;
        } else if (conditions.selected?.value === condition) {
          return product;
        }
      }

      if (categories.selected?.value === category) {
        if (subCategories.selected?.value === subCategory) {
          if (!conditions.selected) {
            return product;
          } else if (conditions.selected?.value === condition) {
            return product;
          }
        } else if (!subCategories.selected) {
          if (!conditions.selected) {
            return product;
          } else if (conditions.selected?.value === condition) {
            return product;
          }
        }
      }
    }
  });

  return <div className="post-feed">{renderProducts}</div>;
};

export default ProductList;
