import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useMainContext } from "../../context/MainContextProvider";
import outOfStock from "../../assets/images/out-of-gun-stock.png";
import loading from "../../assets/images/200.gif";

const Product = ({ product, handleProductClick }) => {
  const {
    getTag,
    getNameFromCategoryId,
    getNameFromSubCategoryId,
    getNameFromConditionId,
  } = useMainContext();

  const useProgressiveImage = (src) => {
    const [sourceLoaded, setSourceLoaded] = React.useState(null);

    React.useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => setSourceLoaded(src);
    }, [src]);

    return sourceLoaded;
  };

  // const useCompressedImage = (path) => {
  //   const supportedExtentions = ['.jpg', '.jpeg', '.png'];
  //   supportedExtentions.map(ext => {
  //     if (path.includes(ext)) {
  //           return path.replace(ext, `_400x300${ext}`);

  //     }
  //   });

  // }

  const {
    category,
    subCategory,
    name,
    image_small,
    videoUrl,
    description,
    price,
    condition,
    inStock,
    stockCount,
  } = product;
  const bgImage = useProgressiveImage(image_small);

  return (
    <span className="post-card">
      <header className="post-card-header">
        <div
          className="post-card-image"
          style={{
            cursor: "pointer",
            backgroundColor: "transparent",
            backgroundSize: `${bgImage ? "cover" : "auto"}`,
            backgroundImage: `url(${
              !inStock ? outOfStock : bgImage || loading
            })`,
          }}
          onClick={() => handleProductClick(product)}
        ></div>
        <a
          className="post-card-title"
          style={{
            fontSize: 15,
            display: "block",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          href={`/products/${getNameFromCategoryId(
            category
          )}/${getNameFromSubCategoryId(subCategory)}`}
          onClick={() => handleProductClick(product)}
        >
          {getTag(category, subCategory)}
        </a>
        <h2
          className="post-card-title"
          onClick={() => handleProductClick(product)}
          style={{ cursor: "pointer" }}
        >
          {name}
        </h2>
        {videoUrl && (
          <h4>
            <a href={videoUrl} target="_blank" rel="noopener noreferrer">
              View video
            </a>
          </h4>
        )}
      </header>
      <section
        className="post-card-excerpt"
        onClick={() => handleProductClick(product)}
        style={{ cursor: "pointer" }}
      >
        <>{ReactHtmlParser(description)}</>
      </section>
      <footer className="post-card-footer">
        {subCategory !== "categories_ammo" && (
          <div className="post-card-footer-left">
            <span style={{ fontWeight: "bold", fontSize: 11 }}>
              Condition: {getNameFromConditionId(condition)}
            </span>
          </div>
        )}
        <div className="post-card-footer-right" style={{ fontSize: 12 }}>
          <div>
            <span>{`£${price}`}</span>
          </div>
        </div>
      </footer>
      {stockCount >= 0 && (
        <footer className="post-card-footer">
          <section className="post-card-footer-left">
            <span style={{ fontWeight: "bold", fontSize: 10 }}>
              Number in stock: {stockCount}
            </span>
          </section>
        </footer>
      )}
    </span>
  );
};

export default Product;
