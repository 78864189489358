import React from 'react';
import { createMessage } from '../apis/MessageAPI';

export const useContact = () => {
  const [messageSent, setMessageSent] = React.useState(false);

  const addMessage = (enquiry, callback) => {
    createMessage(enquiry, (result) => {
      callback();
      setMessageSent(true);
    });
  };

  return {
    addMessage,
    messageSent,
  };
};
