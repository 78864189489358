import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useMainContext } from '../../../context/MainContextProvider';
import { formUseStyles } from '../FormStyles';

const SubcategorySelect = ({ onChange, value, parent = '' }) => {
  const { subCategories } = useMainContext();
  const classes = formUseStyles();

  const getSubCategoryOptions = () => {
    let filtered = subCategories.items;

    if (parent) {
      filtered = subCategories.items.filter((v) => {
        return v.parent === parent;
      });
    }
    return filtered.map((v) => {
      return { value: v.id, label: v.name, parent: v.parent || null };
    });
  };

  return (
    <>
      <InputLabel id="subcatLabel">Subcategory</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="subcatLabel"
          value={value}
          onChange={onChange}
          options={getSubCategoryOptions()}
        ></Select>
      </FormControl>
    </>
  );
};

SubcategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SubcategorySelect;
