import React from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
};

const GoogleMap = ({ google }) => {
  return (
    <Map
      google={google}
      zoom={17}
      style={mapStyles}
      initialCenter={{ lat: 52.818329, lng: -2.11847 }}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDW9bgZ7oBVIGaQ0w74zf8Uxn_ZNbzcavg',
})(GoogleMap);
