import React from 'react';
import GoogleMap from '../../components/google-map/GoogleMap';

const AboutPage = () => {
  return (
    <>
      <main class="site-main" style={{ backgroundColor: '#fff', paddingTop: 120 }}>
        <div class="container">
          <article class="content">
            <section class="post-full-content">
              <section>
                <h1 class="content-title">About</h1>
                <h2>How to find us</h2>
                <figure
                  class="kg-card kg-image-card"
                  id="map"
                  style={{ position: 'relative', height: 400 }}
                >
                  <GoogleMap />
                </figure>
              </section>
              <section>
                <h2>Contact details</h2>
                <p>
                  Phone:
                  <br />
                  01785 241702
                  <br />
                  <br />
                  Address:
                  <br />
                  Unit 4 Marston Road Trading Park,
                  <br />
                  Marston Rd,
                  <br />
                  Stafford
                  <br />
                  ST16 3GB
                </p>
              </section>
              <section>
                <h2>Opening hours</h2>
                <ul>
                  <li>Monday 9am - 5:30pm</li>
                  <li>Tuesday 9am - 5:30pm</li>
                  <li>Wednesday Closed</li>
                  <li>Thursday 9am - 5:30pm</li>
                  <li>Friday 9am - 5:30pm</li>
                  <li>Saturday 9am - 5:30pm</li>
                  <li>Sunday Closed</li>
                </ul>
              </section>
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default AboutPage;
