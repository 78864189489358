import React, { createContext, useContext, useMemo } from 'react';
import { useAdminAuth } from '../hooks/AdminAuthHook';

export const AdminContext = createContext({});

export const AdminContextProvider = ({ children }) => {
  const adminAuthManager = useAdminAuth();

  const adminManagerContext = useMemo(
    () => ({
      ...adminAuthManager,
    }),
    [adminAuthManager.isAuthenticated, adminAuthManager.loginError]
  );

  return <AdminContext.Provider value={adminManagerContext}>{children}</AdminContext.Provider>;
};

export const useAdminContext = () => {
  return useContext(AdminContext);
};
